import React from "react";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import MainTable from "components/Table/MainTable";
import { Accordion, Card } from "react-bootstrap";
import InputField from "components/InputField/InputField";

const FormationPanel = ({
  formationCodeObj,
  modifireTableData,
  PERSONALIZE,
  quickTools,
  eventKey,
  handleCptChange,
  handleCellClick,
  handleAdd,
  isDisabled,
}) => {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header className="bg-transparent">
        <Card.Header
          onClick={(e) => e.stopPropagation()}
          className="p-0 border-0 rounded-0 bg-transparent w-100 cursor-default"
        >
          <div className="d-flex justify-content-between align-items-center pe-4">
            <Card.Title as="h5" style={{ fontWeight: "bold" }}>
              CPT Specific Modifiers
            </Card.Title>
          </div>
        </Card.Header>
      </Accordion.Header>
      <Accordion.Body>
        <div className="d-flex justify-content-start align-items-center gap-3 pt-2 px-0">
          <InputCPTCodeDropDown
            type="text"
            inputStyle="modalInput"
            labelStyle=" text-capitalize"
            groupWrapper="mb-0 w-30"
            label={"CPT A"}
            placeholder={"CPT A"}
            optionList={[]}
            value={formationCodeObj?.cptA?.internalCode || formationCodeObj?.cptA?.proc_code || ""}
            handleChange={(opt) => handleCptChange({ ...opt, key: "cptA" })}
            searchtype="cpt"
          />
          <InputCPTCodeDropDown
            type="text"
            inputStyle="modalInput"
            labelStyle=" text-capitalize"
            groupWrapper="mb-0 w-30"
            label={"CPT B"}
            placeholder={"CPT B"}
            optionList={[]}
            value={formationCodeObj?.cptB?.internalCode || formationCodeObj?.cptB?.proc_code || ""}
            handleChange={(opt) => handleCptChange({ ...opt, key: "cptB" })}
            searchtype="cpt"
          />

          <InputField
            type="text"
            inputStyle="modalInput"
            labelStyle="mt-0 modalLineHeaders text-capitalize"
            label="Modifire"
            value={formationCodeObj?.modifire || ""}
            maxlength={2}
            placeholder="Type Modifire"
            handleChange={(e) =>
              handleCptChange({
                key: "modifire",
                value: e.target.value,
              })
            }
            groupWrapper="w-30"
          />

          <div className="mt-4">
            <i
              className={`fas fa-plus-circle fa-lg cursor-pointer ${isDisabled ? "custom-disabled" : ""}`}
              alt="addCli"
              title="add"
              onClick={handleAdd}
            />
          </div>
        </div>
        <div className="table-full-width desktop-noScroll accordion-body-custom">
          {modifireTableData.length > 0 && (
            <MainTable
              columns={PERSONALIZE}
              rows={modifireTableData}
              widthToSkip={200}
              handleCellClick={handleCellClick}
              tools={quickTools}
            />
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default FormationPanel;
