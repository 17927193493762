import React from "react";
import { Modal, Button } from "react-bootstrap";
import { vhToPixels } from "utils";
import { useDispatch } from "react-redux";
import { setERADocumentView } from "store/features/general/generalSlice";

const DocumentPreviewModal = ({ isModalOpen, closeModal, document, cssClass }) => {
  
  const dispatch = useDispatch();

  return (
    <Modal
      show={isModalOpen}
      backdrop="static"
      className={cssClass}
      onHide={() => closeModal(false)}
      size="3xl"
      centered
    >
      <Modal.Header closeButton className="h-25" style={{ marginBottom: "0px" }}>
        <Modal.Title className="m-0 w-100">
          <div className="d-flex justify-content-between">
            <span>{document.title || "Document Preview"} </span>
            {document.user && document?.user?.type === "Patient" && (
              <span className="pe-4 d-inline-block cursor-pointer" onClick={() => dispatch(setERADocumentView({ ...document.user, type: "Full" }))}>
                View Full ERA
              </span>
            )}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center" style={{ padding: "0px", paddingBottom: "5px" }}>
        {document.fileType === "image" ? (
          <img src={document.fileUrl} alt="Document Preview" style={{ maxWidth: "100%", height: "auto" }} />
        ) : (
          <iframe
            src={document.fileUrl}
            type="application/pdf"
            width="100%"
            height={vhToPixels(80)}
            title="Document Preview"
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentPreviewModal;
