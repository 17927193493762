import axios from "axios";
import { API, Auth } from "aws-amplify";
import api from "../api";
import { userCompanyID } from "utils";


const apiName = "apif79b844d";

const fetchInsurances = async (param) => {
  let path = "/insurances/list";
  
  try {
    const models = await api.callAmplifyAPI(path, param)

    return models || {};
  } catch (e) {
    console.log("Error getting users:", e);
    return {rows: []};
  }
};

const fetchCompletePayerList = async (param) => {
  let path = "/insurances/all";
  
  try {
    const models = await api.callAmplifyAPI(path, param)

    return models ||[];
  } catch (e) {
    console.log("Error getting users:", e);
    return {rows: []};
  }
};

const createInsurances = async (params) => {
  let path = "/insurances";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      ...params,
    },
  };

  try {
    const models = await API.post(apiName, path, myInit);

    return models;
  } catch (e) {
    console.log("Error getting users:", e);
    return null;
  }
};

const updateInsurances = async (params) => {
  let path = "/insurances";
  
  try {
    const models = await api.callAmplifyAPIPut(path, params);
    return models?.Item || null;
  } catch (e) {
    console.log("Error getting users:", e);
    return null;
  }
};

export default {
  fetchCompletePayerList,
  fetchInsurances,
  createInsurances,
  updateInsurances,
};
