import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MESSAGE_MODES, INSURANCE_PROVIDER } from "constant";
import { userCompanyID } from "utils";
import insuranceServices from "../../../services/InsuranceService";

import { setMessage } from "../general/generalAction";
import { setLoader, showMessage } from "../general/generalSlice";
import {
  INSURANCES,
  INSURANCES_CREATE,
  INSURANCES_DELETE,
  INSURANCES_FETCH,
  INSURANCES_FETCH_ALL,
  INSURANCES_UPDATE,
} from "./insuranceConstant";

export const fetchInsurancesAsync = createAsyncThunk(INSURANCES_FETCH, async (param, { dispatch }) => {
  return await insuranceServices.fetchInsurances(param);
});

export const fetchPayerAsync = createAsyncThunk(INSURANCES_FETCH_ALL, async (param, { dispatch }) => {
  return await insuranceServices.fetchCompletePayerList(param);
});

export const createInsuranceAsync = createAsyncThunk(INSURANCES_CREATE, async (param, { dispatch, getState }) => {
  const loginUser = getState().auth.user;
  dispatch(setLoader(true));
  const response = await insuranceServices.createInsurances({
    ...param,
    updatedByName: loginUser.name,
    updatedBy: loginUser.sub,
  });
  dispatch(setLoader(false));

  if (response && response.rows && response.rows.length > 0) {
    dispatch(setMessage("Insurance Added Sucessfully", MESSAGE_MODES.success));
    return { ...response.rows[0], Name: response.rows[0].name };
  } else {
    dispatch(setMessage(`${response?.error || "Insurance Not Created"}`, MESSAGE_MODES.error));
  }
  return null;
});

export const updateInsuranceAsync = createAsyncThunk(INSURANCES_UPDATE, async (param, { dispatch, getState }) => {
  const loginUser = getState().auth.user;
  dispatch(setLoader(true));

  const response = await insuranceServices.updateInsurances({
    ...param,
    updatedByName: loginUser.name,
    updatedBy: loginUser.sub,
    clientID: userCompanyID.get(),
  });
  dispatch(setLoader(false));
  if (response) {
    dispatch(setMessage("Insurance Updated Sucessfully", MESSAGE_MODES.success));
    return { ...response, Name: response.name };
  } else {
    dispatch(setMessage(`${response?.error || "Insurance Not Updated"}`, MESSAGE_MODES.error));
  }
  return null;
});

export const deleteInsuranceAsync = createAsyncThunk(INSURANCES_DELETE, async (defaultSetting) => {
  // const response = await api.updateCliItem(defaultSetting); // Your API call logic here
  return {};
});

const insuranceSlice = createSlice({
  name: INSURANCES,
  initialState: {
    payerList: [],
    insurancesList: [],
    filteredInsurances: [],
    openInsuranceModal: false,
    selectedInsurance: null,
    openInsruModal: false,
    totalInsurancesRecords: 0,
  },
  reducers: {
    setInsurnaceList: (state, action) => {
      state.insurancesList = action.payload;
    },
    setFilteredInsurnaceList: (state, action) => {
      state.filteredInsurances = action.payload;
    },
    setOpenInsuranceModal: (state, action) => {
      state.openInsuranceModal = action.payload;
    },
    setSelectedInsurance: (state, action) => {
      state.selectedInsurance = action.payload;
    },
    setOpenInsruModal: (state, action) => {
      state.openInsruModal = action.payload;
    },
    updateInsuranceOnSubscription: (state, action) => {
      let updatedObject = action.payload.model;
      if (updatedObject) {
        const existingIndex = state.insurancesList.findIndex((provider) => provider.id === updatedObject.id);
        if (existingIndex !== -1) {
          state.insurancesList[existingIndex] = updatedObject;
        } else if (action.payload.event === "INSERT") {
          state.insurancesList.unshift(updatedObject);
        }
        const existingFilteredIndex = state.filteredInsurances.findIndex(
          (provider) => provider.id === updatedObject.id
        );
        if (existingFilteredIndex !== -1) {
          state.filteredInsurances[existingFilteredIndex] = updatedObject;
        } else if (action.payload.event === "INSERT") {
          state.filteredInsurances.unshift(updatedObject);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInsurancesAsync.fulfilled, (state, action) => {
        state.insurancesList = action.payload.rows;
        state.filteredInsurances = action.payload.rows;
        state.totalInsurancesRecords = action.payload.total;
      })
      .addCase(fetchPayerAsync.fulfilled, (state, action) => {
        state.payerList = action.payload?.rows || INSURANCE_PROVIDER;
      })
      .addCase(createInsuranceAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.insurancesList.unshift(action.payload);
          state.filteredInsurances.unshift(action.payload);
          state.payerList.unshift(action.payload);
        }
      })
      .addCase(updateInsuranceAsync.fulfilled, (state, action) => {
        const updateItem = action.payload;
        if (updateItem) {
          const existingclientIndex = state.insurancesList.findIndex((code) => code.id === updateItem.id);
          if (existingclientIndex !== -1) {
            state.insurancesList[existingclientIndex] = updateItem;
          } else {
            state.insurancesList.unshift(updateItem);
          }
          const existingIndex = state.filteredInsurances.findIndex((code) => code.id === updateItem.id);
          if (existingIndex !== -1) {
            state.filteredInsurances[existingIndex] = updateItem;
          }
          const payerIndex = state.payerList.findIndex((code) => code.id === updateItem.id);
          if (payerIndex !== -1) {
            state.payerList[existingIndex] = updateItem;
          } else {
            state.payerList.unshift(updateItem);
          }
        }
      });
  },
});

export const {
  setInsurnaceList,
  setFilteredInsurnaceList,
  setOpenInsuranceModal,
  setSelectedInsurance,
  setOpenInsruModal,
  updateInsuranceOnSubscription,
} = insuranceSlice.actions;

export default insuranceSlice.reducer;
