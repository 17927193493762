import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import { calcHeight } from "utils";

const ConfirmationModal = (props) => {
  const { title, message, handleConfirm, closeBtn, actionType, handleClose, cssClass, note } = props;
  const [value, setValue] = useState("");
  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      onHide={() => {
        handleClose ? handleClose() : handleConfirm(false, actionType, value);
      }}
      className={`second-modal ${cssClass}`}
      centered
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
        <Modal.Title id="contained-modal-title-vcenter">{message}</Modal.Title>
        {note && note.label && (
          <div className="w-100 mt-2">
            <label className="profile-title">{note.label}</label>
            <div className="mb-3">
              <textarea
                className="form-control reason-textarea w-100"
                ref={(el) => {
                  if (el) {
                    el.style.setProperty("height", `${calcHeight(el.value, el.offsetWidth) + "px"}`, "important");
                  }
                }}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              ></textarea>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleConfirm(false, actionType, value);
          }}
        >
          {closeBtn ? "No" : "Go back"}
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={() => {
            handleConfirm(true, actionType, value);
          }}
        >
          {closeBtn ? "Yes" : "Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
