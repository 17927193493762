import React from "react";
import { Accordion, Card } from "react-bootstrap";
import Select from "react-select";
import MainTable from "components/Table/MainTable";
import { CODING_RULE_PERSONALIZE } from "constants/personalization";

const PanelClaim = ({ panelOptions, handleAddPanel, panelData, handleCellClick, quickTools, title, eventKey }) => (
  <Accordion.Item eventKey={eventKey}>
    <Accordion.Header className="bg-transparent coding-rules-wrapper">
      <Card.Header
        onClick={(e) => e.stopPropagation()}
        className="p-0 border-0 rounded-0 bg-transparent w-100 cursor-default"
      >
        <div className="d-flex justify-content-between align-items-center pe-4">
          <Card.Title as="h5" style={{ fontWeight: "bold" }}>
            {title}
          </Card.Title>
        </div>
      </Card.Header>
    </Accordion.Header>
    <Accordion.Body>
      <Select
        options={panelOptions}
        blurInputOnSelect={true}
        menuPlacement="auto"
        placeholder={"Select panel to add in list"}
        className="w-30"
        onChange={handleAddPanel}
      />
      <div className="table-full-width desktop-noScroll accordion-body-custom">
        <MainTable
          columns={CODING_RULE_PERSONALIZE}
          rows={panelData}
          widthToSkip={200}
          handleCellClick={handleCellClick}
          tools={quickTools}
        />
      </div>
    </Accordion.Body>
  </Accordion.Item>
);

export default PanelClaim;
