import { TABLE_QUICK_TOOLS } from "constant";
import { CPT_CODES_PERSONALIZE, CPT_MODIFIRE_PERSONALIZE } from "constants/personalization";
import { useContext, useEffect, useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { showConfirmationModal } from "store/features/general/generalAction";

import api from "api";
import Loader from "components/Loader/Loader";
import { customCloneDeep } from "util/customLodash";
import { userCompanyID, isCptCodeObjInDb } from "utils";
import PanelClaim from "./CodingRulesComponents/PanelClaim";
import CPTCodePanel from "./CodingRulesComponents/CPTCodePanel";
import { v4 as uuidv4 } from "uuid";

import { parseStringIntoFloat } from "utils";
import FormationPanel from "./CodingRulesComponents/FormationPanel";
import { AppContext } from "context/app-context";
import { isCptModifireObjInDb } from "utils";
import { isModifireObjInDb } from "utils";

const CodingRules = () => {
  const dispatch = useDispatch();
  const [client, setClient] = useState(null);

  const [loading, setLoading] = useState(false);

  const [panelOptions, setPanelOptions] = useState([]);
  const [panelList, setPanelList] = useState([]);

  const context = useContext(AppContext);

  const [cptCodeObj, setCptCodeObj] = useState(null);

  const [cptTableData, setCptTableData] = useState([]);

  const [modifireCodeObj, setModifireCodeObj] = useState(null);

  const [modifireTableData, setModifireTableData] = useState([]);

  const quickTools = [TABLE_QUICK_TOOLS.delete];

  const fetchPanelList = async () => {
    const { data } = await api.getLabClientTests();
    if (data) {
      setPanelList(data.sort((a, b) => a.name.localeCompare(b.name)));
    }
  };

  const fetchClientData = async () => {
    if (userCompanyID) {
      const fetchedClient = await api.getClientById(userCompanyID.get());
      setClient(fetchedClient);
      setCptTableData(fetchedClient?.mdSetting?.cptCodesList || []);
      setModifireTableData(fetchedClient?.mdSetting?.cptModifiresList || []);
    }
  };

  useEffect(() => {
    fetchPanelList();
  }, []);

  useEffect(() => {
    fetchClientData();
  }, [userCompanyID]);

  useEffect(() => {
    const fetchAllTests = async () => {
      if (!client || !panelList.length) return;
      setLoading(true);

      const existingIds = client?.mdSetting?.panelList?.map((panel) => panel.id) || [];

      const testList =
        panelList
          ?.filter((item) => !existingIds.includes(item.id))
          .map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          })) || [];

      setPanelOptions(testList);
      setLoading(false);
    };
    fetchAllTests();
  }, [client, panelList]);

  const deletePanel = async (row) => {
    const cloneClient = customCloneDeep(client);

    cloneClient.mdSetting = cloneClient.mdSetting || {};
    cloneClient.mdSetting.panelList = cloneClient.mdSetting.panelList || [];

    cloneClient.mdSetting.panelList = cloneClient.mdSetting.panelList.filter((item) => item.id !== row.id);
    setClient(cloneClient);

    setPanelOptions((prevOptions) => [...prevOptions, { label: row.name, value: row.id }]);

    await api.updateClient(cloneClient);
  };

  const deleteCpt = async (row) => {
    setLoading(true);

    const cloneClient = customCloneDeep(client);

    cloneClient.mdSetting.cptCodesList = cloneClient.mdSetting.cptCodesList.filter((item) => item.id !== row.id);

    setClient(cloneClient);

    await api.updateClient(cloneClient);

    setLoading(false);

    fetchClientData();
  };

  const deleteModifire = async (row) => {
    setLoading(true);

    const cloneClient = customCloneDeep(client);

    cloneClient.mdSetting.cptModifiresList = cloneClient.mdSetting.cptModifiresList.filter(
      (item) => item.id !== row.id
    );

    setClient(cloneClient);

    await api.updateClient(cloneClient);

    setLoading(false);

    fetchClientData();
  };

  const handleCellClick = (key, row) => {
    if (key === TABLE_QUICK_TOOLS.delete) {
      const confirmationModalData = {
        showModal: true,
        onConfirm: () => deletePanel(row),
        title: "Remove Panel",
        message: "Are you sure you want to remove this panel?",
      };
      dispatch(showConfirmationModal(confirmationModalData));
    }
  };

  const handleAddPanel = async (e) => {
    const cloneClient = customCloneDeep(client);

    cloneClient.mdSetting = cloneClient.mdSetting || {};
    cloneClient.mdSetting.panelList = cloneClient.mdSetting.panelList || [];

    cloneClient.mdSetting.panelList.push({ id: e.id, name: e.name });
    setClient(cloneClient);

    setPanelOptions((prevOptions) => prevOptions.filter((option) => option.value !== e.id));

    await api.updateClient(cloneClient);
  };

  const handleCptChange = (opt) => {
    setCptCodeObj({
      ...cptCodeObj,
      [opt.key]: {
        proc_code: opt.value,
        charge: parseStringIntoFloat(opt.charges),
        internalCode: opt.internalCode,
        proc_desc: opt.description,
        proc_name: opt.description,
      },
    });
  };

  const handleModifireCptChange = (opt) => {
    if (opt.key === "modifire") {
      setModifireCodeObj({
        ...modifireCodeObj,
        [opt.key]:  opt.value.toUpperCase(),
      });
      return;
    }

    setModifireCodeObj({
      ...modifireCodeObj,
      [opt.key]: {
        proc_code: opt.value,
        charge: parseStringIntoFloat(opt.charges),
        internalCode: opt.internalCode,
        proc_desc: opt.description,
        proc_name: opt.description,
      },
    });
  };

  const handleAddCptRow = async () => {
    setLoading(true);

    const cloneClient = customCloneDeep(client);

    cloneClient.mdSetting = cloneClient?.mdSetting || {};

    cloneClient.mdSetting.cptCodesList = cloneClient.mdSetting.cptCodesList || [];

    if (cptCodeObj?.cptA?.proc_code === cptCodeObj?.cptB?.proc_code) {
      context.showErrorMessage("Both cpt is same. Try Different!");
      setLoading(false);
      return;
    }

    const objToSave = {
      id: uuidv4(),
      cptCodeObj,
      cptA: `${cptCodeObj?.cptA?.proc_code || ""} - ${cptCodeObj?.cptA?.proc_desc || ""}`,
      cptB: `${cptCodeObj?.cptB?.proc_code || ""} - ${cptCodeObj?.cptB?.proc_desc || ""}`,
    };

    const isPairAlreadyExist = isCptCodeObjInDb({ cptCodeObj, clientDbList: cloneClient.mdSetting.cptCodesList });

    if (isPairAlreadyExist) {
      context.showErrorMessage("This pair already exist. Try Different!");
      setLoading(false);

      return;
    }

    cloneClient.mdSetting.cptCodesList.push(objToSave);

    setClient(cloneClient);

    await api.updateClient(cloneClient);

    setCptCodeObj(null);
    fetchClientData();
    setLoading(false);
  };

  const handleCptCellClick = (key, row) => {
    if (key === TABLE_QUICK_TOOLS.delete) {
      const confirmationModalData = {
        showModal: true,
        onConfirm: () => deleteCpt(row),
        title: "Remove Item",
        message: `Are you sure you want to remove this item?`,
      };
      dispatch(showConfirmationModal(confirmationModalData));
    }
  };

  const handleModifireCellClick = (key, row) => {
    if (key === TABLE_QUICK_TOOLS.delete) {
      const confirmationModalData = {
        showModal: true,
        onConfirm: () => deleteModifire(row),
        title: "Remove Item",
        message: `Are you sure you want to remove this item?`,
      };
      dispatch(showConfirmationModal(confirmationModalData));
    }
  };

  const handleAddCptModifire = async () => {
    setLoading(true);

    const cloneClient = customCloneDeep(client);

    if (modifireCodeObj?.cptA?.proc_code === modifireCodeObj?.cptB?.proc_code) {
      context.showErrorMessage("Both cpt is same. Try Different!");
      setLoading(false);
      return;
    }

    const isPairAlreadyExist = isCptModifireObjInDb({
      cptCodeObj: modifireCodeObj,
      clientDbList: cloneClient?.mdSetting?.cptModifiresList || [],
    });

    if (isPairAlreadyExist) {
      context.showErrorMessage("This pair already exist. Try Different!");
      setLoading(false);
      return;
    }

    const isModifireAlreadyExist = isModifireObjInDb({
      modifireCodeObj,
      clientDbList: cloneClient?.mdSetting?.cptModifiresList || [],
    });

    if (isModifireAlreadyExist) {
      context.showErrorMessage("This modifire already exist. Try Different!");
      setLoading(false);
      return;
    }

    await saveModifire();

    setLoading(false);
  };

  const saveModifire = async () => {
    const cloneClient = customCloneDeep(client);

    cloneClient.mdSetting = cloneClient?.mdSetting || {};

    cloneClient.mdSetting.cptModifiresList = cloneClient.mdSetting.cptModifiresList || [];
    const objToSave = {
      id: uuidv4(),
      modifireCodeObj: modifireCodeObj,
      cptA: `${modifireCodeObj?.cptA?.proc_code || ""} - ${modifireCodeObj?.cptA?.proc_desc || ""}`,
      cptB: `${modifireCodeObj?.cptB?.proc_code || ""} - ${modifireCodeObj?.cptB?.proc_desc || ""}`,
      modifire: modifireCodeObj.modifire,
    };

    cloneClient.mdSetting.cptModifiresList.push(objToSave);

    setClient(cloneClient);

    await api.updateClient(cloneClient);

    setModifireCodeObj(null);
    fetchClientData();
  };

  return (
    <Row>
      <Col md={12}>
        <Card className="strpied-tabled-with-hover">
          {loading && <Loader />}
          <Accordion defaultActiveKey="0" className="coding-rules-wrapper ">
            <div className="p-3 px-4 d-flex justify-content-between">
              <Card.Title as="h4" style={{ fontWeight: "bold" }}>
                Coding Rules
              </Card.Title>
            </div>

            <PanelClaim
              panelOptions={panelOptions}
              handleAddPanel={handleAddPanel}
              panelData={client?.mdSetting?.panelList || []}
              handleCellClick={handleCellClick}
              quickTools={quickTools}
              title="Separate Claim For Panel"
              eventKey="0"
            />

            <CPTCodePanel
              cptObj={cptCodeObj}
              cptTableData={cptTableData}
              PERSONALIZE={CPT_CODES_PERSONALIZE}
              quickTools={quickTools}
              eventKey="1"
              handleCptChange={handleCptChange}
              handleCellClick={handleCptCellClick}
              handleAddCptRow={handleAddCptRow}
              isDisabled={!cptCodeObj?.cptA?.proc_code || !cptCodeObj?.cptB?.proc_code}
            />

            <FormationPanel
              formationCodeObj={modifireCodeObj}
              modifireTableData={modifireTableData || []}
              PERSONALIZE={CPT_MODIFIRE_PERSONALIZE}
              quickTools={quickTools}
              eventKey="2"
              handleCptChange={handleModifireCptChange}
              handleCellClick={handleModifireCellClick}
              handleAdd={handleAddCptModifire}
              isDisabled={!modifireCodeObj?.cptA || !modifireCodeObj?.cptB || !modifireCodeObj?.modifire}
            />
          </Accordion>
        </Card>
      </Col>
    </Row>
  );
};

export default CodingRules;
