import React, { memo, useState } from "react";
import { Button, Modal, Card, Accordion } from "react-bootstrap";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import { TABLE_QUICK_TOOLS } from "constant";
import { CPT_NOT_TO_PAY_LIST } from "constants/personalization";
import { showConfirmationModal } from "store/features/general/generalAction";
import { useDispatch, useSelector } from "react-redux";
import MainTable from "components/Table/MainTable";
import { customCloneDeep } from "util/customLodash";
import {
  updateInsuranceAsync,
  fetchInsurancesAsync,
  setSelectedInsurance,
} from "store/features/insurances/insuranceSlice";
import { insurnaceSelector } from "../../store/features/insurances/insuranceSelectors";

const NotToPaySection = ({ codeType, notToPayList, codeObj, onCodeChange, onAddCode, onDeleteCode, quickTools }) => {
  const codeLabel = `${codeType} not To Bill`;
  const searchType = codeType.toLowerCase();

  return (
    <Accordion.Item eventKey={codeType}>
      <Accordion.Header className="bg-transparent">
        <Card.Header
          onClick={(e) => e.stopPropagation()}
          className="p-0 border-0 rounded-0 bg-transparent w-100 cursor-default"
        >
          <div className="d-flex justify-content-between align-items-center pe-4">
            <Card.Title as="h5" style={{ fontWeight: "bold" }}>
              {codeLabel}
            </Card.Title>
          </div>
        </Card.Header>
      </Accordion.Header>
      <Accordion.Body>
        <div className="d-flex justify-content-start align-items-center gap-3 pt-2 px-0">
          <InputCPTCodeDropDown
            type="text"
            inputStyle="modalInput"
            labelStyle="text-capitalize"
            groupWrapper="mb-0 w-30"
            placeholder="Search Code"
            optionList={[]}
            value={codeObj?.internalCode || codeObj?.proc_code || ""}
            handleChange={onCodeChange}
            searchtype={searchType}
          />

          <div>
            <i
              className={`fas fa-plus-circle fa-lg cursor-pointer mt-2 ${codeObj === null ? "custom-disabled" : ""}`}
              alt="addCli"
              title="add"
              onClick={onAddCode}
            />
          </div>
        </div>
        <div className="table-full-width desktop-noScroll accordion-body-custom">
          {notToPayList?.length > 0 && (
            <MainTable
              columns={CPT_NOT_TO_PAY_LIST.map((item)=>({...item, 
                id: `${codeType}_${item.id || index}`,
              }))}
              rows={notToPayList}
              widthToSkip={200}
              handleCellClick={onDeleteCode}
              tools={quickTools}
            />
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const InsuranceNotPayModal = ({ handleClose, setInsuranceSettingCpt, setInsuranceSettingIct }) => {
  const quickTools = [TABLE_QUICK_TOOLS.delete];
  const { selectedInsurance } = useSelector(insurnaceSelector);

  const [codeObjs, setCodeObjs] = useState({
    CPT: null,
    ICD: null,
  });

  const dispatch = useDispatch();

  const handleCodeChange = (codeType, codeObj) => {
    setCodeObjs((prev) => ({
      ...prev,
      [codeType]: codeObj,
    }));
  };

  // Generalized function to add codes
  const handleAddCodeRow = async (codeType, codeObj) => {
    if (!codeObj) return;

    let insuranceClone = customCloneDeep(selectedInsurance);

    insuranceClone.setting = {
      ...insuranceClone?.setting,
      cptNotToPayList: insuranceClone?.setting?.cptNotToPayList || [],
      ictNotToPayList: insuranceClone?.setting?.ictNotToPayList || [],
    };

    const codeListKey = codeType === "CPT" ? "cptNotToPayList" : "ictNotToPayList";


    console.log('insuranceClone.setting',insuranceClone.setting)

    const alreadyAdded = insuranceClone.setting[codeListKey].some((item) => item.code === codeObj.code);

    if (!alreadyAdded) {

      console.log("insuranceClone",insuranceClone)

      const dataToAdd = [...insuranceClone.setting[codeListKey], { code: codeObj.code, title: codeObj.title }];
      insuranceClone.setting[codeListKey] = dataToAdd;
      if (insuranceClone?.id) {
        await dispatch(updateInsuranceAsync(insuranceClone));
        await dispatch(setSelectedInsurance(insuranceClone));
        dispatch(fetchInsurancesAsync({ orderByDirection: true }));
      } else {
        await dispatch(setSelectedInsurance(insuranceClone));
        codeType === "CPT" ? setInsuranceSettingCpt(dataToAdd) : setInsuranceSettingIct(dataToAdd);
      }
    }


    setCodeObjs((prev) => ({
      ...prev,
      [codeType]: null,
    }));
  };

  const handleDeleteCode = async (codeType, row) => {
    let insuranceClone = customCloneDeep(selectedInsurance);

    insuranceClone.setting = {
      ...insuranceClone?.setting,
      cptNotToPayList: insuranceClone?.setting?.cptNotToPayList || [],
      ictNotToPayList: insuranceClone?.setting?.ictNotToPayList || [],
    };

    const codeListKey = codeType === "CPT" ? "cptNotToPayList" : "ictNotToPayList";

    insuranceClone.setting[codeListKey] = insuranceClone.setting[codeListKey].filter((item) => item.code !== row.code);

    if (insuranceClone?.id) {
      await dispatch(updateInsuranceAsync(insuranceClone));
      await dispatch(setSelectedInsurance(insuranceClone));
      dispatch(fetchInsurancesAsync({ orderByDirection: true }));
    } else {
      await dispatch(setSelectedInsurance(insuranceClone));
      codeType === "CPT"
        ? setInsuranceSettingCpt(insuranceClone.setting[codeListKey])
        : setInsuranceSettingIct(insuranceClone.setting[codeListKey]);
    }
  };

  const handleCellDelete = (codeType, key, row) => {
    if (key === TABLE_QUICK_TOOLS.delete) {
      const confirmationModalData = {
        showModal: true,
        onConfirm: () => handleDeleteCode(codeType, row),
        title: `Remove ${codeType}`,
        message: "Are you sure you want to remove this item?",
      };
      dispatch(showConfirmationModal(confirmationModalData));
    }
  };

  return (
    <Modal show animation={true} size="2xl" onHide={handleClose} className="second-modal" backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">CPT and ICD Setting</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <Accordion defaultActiveKey="CPT" className="coding-rules-wrapper">
          <NotToPaySection
            codeType="CPT"
            notToPayList={selectedInsurance?.setting?.cptNotToPayList}
            codeObj={codeObjs.CPT}
            onCodeChange={(codeObj) => handleCodeChange("CPT", codeObj)}
            onAddCode={() => handleAddCodeRow("CPT", codeObjs.CPT)}
            onDeleteCode={(key, row) => handleCellDelete("CPT", key, row)}
            quickTools={quickTools}
          />
          <NotToPaySection
            codeType="ICD"
            notToPayList={selectedInsurance?.setting?.ictNotToPayList}
            codeObj={codeObjs.ICD}
            onCodeChange={(codeObj) => handleCodeChange("ICD", codeObj)}
            onAddCode={() => handleAddCodeRow("ICD", codeObjs.ICD)}
            onDeleteCode={(key, row) => handleCellDelete("ICD", key, row)}
            quickTools={quickTools}
          />
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(InsuranceNotPayModal);
