import React, { useCallback, useEffect, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import styles from "components/InputField/InputStyle.module.css";
import { debounce } from "debounce";
import { ViewportList } from "react-viewport-list";
import api from "../../api";
import insServices from "services/InsuranceService";
import { convertToUpper, userCompanyID } from "utils";
import { CONFIG } from "constant";
import OptionListItem from "./OptionListItem";
import { CapsFirstLetter } from "utils";

const InputCPTCodeDropDown = (props) => {
  const {
    label,
    type,
    placeholder,
    handleChange,
    labelStyle,
    inputStyle,
    value,
    maxlength,
    filterBy,
    filterValue,
    groupWrapper,
    optionList,
    capsCase,
    readOnly,
    selectedDiagnosis,
    searchtype,
    required,
    isClear = false,
  } = props;

  const [input, setInput] = useState(value || "");
  const [description, setDescription] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const scrollableContainerRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const inputRef = useRef(null);

  const handleTypeDelay = useCallback(
    debounce((data, selectedDiagnosis, filterBy, filterValue) => {
      handleChangeInputText(data, selectedDiagnosis, filterBy, filterValue);
    }, 200),
    []
  );

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedOptionIndex((prevIndex) => (prevIndex < filteredList.length - 1 ? prevIndex + 1 : prevIndex));
      scrollToOption(selectedOptionIndex + 1);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedOptionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      scrollToOption(selectedOptionIndex - 1);
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (selectedOptionIndex !== -1) {
        const selectedOption = filteredList[selectedOptionIndex];
        if (selectedOption) {
          if (isClear) {
            setInput("");
          } else {
            setInput(selectedOption.code);
          }
          handleChange({
            ...selectedOption,
            value: selectedOption.code,
            label: selectedOption.description,
          });
          setFilteredList([]);
        }
      }
    }
  };

  useEffect(async () => {
    if (value && value.trim()) {
      setInput(value);
    } else {
      setInput("");
      setDescription("");
    }
    setSelectedOptionIndex(-1);
  }, [value]);

  useEffect(() => {
    if (!input || !input.trim()) {
      setFilteredList([]);
      setSelectedOptionIndex(-1);
    }
  }, [input]);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (isDropdownOpen && !e.target.closest(".form-group-wrapper")) {
        // Close the dropdown when clicking outside of it
        setIsDropdownOpen(false);
      }
    };

    // Add event listener to detect clicks outside of the dropdown
    document.addEventListener("click", handleOutsideClick);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isDropdownOpen]);

  const scrollToOption = (index) => {
    if (scrollableContainerRef.current) {
      const listItem = scrollableContainerRef.current.querySelector(`.listOption-${index}`);
      if (listItem) {
        const containerRect = scrollableContainerRef.current.getBoundingClientRect();
        const listItemRect = listItem.getBoundingClientRect();
        if (listItemRect.bottom > containerRect.bottom) {
          // Scroll down to make the selected item visible
          scrollableContainerRef.current.scrollTop += listItemRect.bottom - containerRect.bottom;
        } else if (listItemRect.top < containerRect.top) {
          // Scroll up to make the selected item visible
          scrollableContainerRef.current.scrollTop -= containerRect.top - listItemRect.top;
        }
      }
    }
  };

  const handleChangeInputText = async (data, selectedDiagnosis, filterBy, filterValue) => {
    if (!data || !data.trim()) {
      setFilteredList([]);
      return;
    }

    let response = [];
    if (searchtype === "cpt") {
      const apiRes = await api.searchCptCode({ search: data });
      response = apiRes.rows;
    }

    if (searchtype === "ict" || searchtype === "icd") {
      const apiRes = await api.searchICTCode({ search: data });
      response = apiRes.rows;
      if (selectedDiagnosis && selectedDiagnosis.length > 0 && response && response.length > 0) {
        response = response.filter((f) => !selectedDiagnosis.includes(f.code));
      }
    }

    if (searchtype === "insurance" || searchtype.toLowerCase() === "payer") {
      const res = await insServices.fetchInsurances({ searchKey: data });
      response = res?.rows?.map((m) => ({ ...m, internalCode: m.Name, description: m.payerId })) || [];
    }

    if (searchtype === "claims") {
      const params = {
        search: data,
      };

      if (CONFIG.isLabType) {
        Object.assign(params, {
          clientID: userCompanyID.get(),
        });
      }
      if (filterBy && filterValue) {
        Object.assign(params, { [filterBy]: filterValue });
      }
      const res = await api.getClaimList(params);
      response = res.rows;
    }

    setFilteredList(response);
  };

  const optionRow = (option, index) => (
    <OptionListItem
      option={option}
      key={option.code || index}
      index={index}
      isSelected={index === selectedOptionIndex}
      searchtype={searchtype}
      setInput={setInput}
      handleChange={handleChange}
      setFilteredList={setFilteredList}
      isClear={isClear}
    />
  );

  return (
    <Form.Group className={`${groupWrapper ? groupWrapper : "form-group-wrapper"}`}>
      {label ? (
        <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>{`${label} ${required ? "*" : ""}`}</Form.Label>
      ) : (
        ""
      )}
      <div className="position-relative w-100">
        <Form.Control
          ref={inputRef}
          type={type}
          maxLength={maxlength}
          onKeyDown={handleKeyDown}
          onClick={() => {
            setIsDropdownOpen(true);
          }}
          title={description}
          value={input}
          readOnly={readOnly}
          placeholder={placeholder}
          className={`${styles.inputStyling} ${inputStyle} inputCptDropDownField`}
          onChange={(e) => {
            const val = capsCase ? CapsFirstLetter(e.target.value) : convertToUpper(e.target.value);
            setIsDropdownOpen(true);
            if (val) {
              setInput(val);
              setDescription("");
              handleTypeDelay(val, selectedDiagnosis, filterBy, filterValue);
            } else {
              handleTypeDelay("");
              handleChange("");
              setInput(val);
            }
          }}
        />
        {value && !readOnly && (
          <i
            className="fas fa-times cancelSearchInput"
            onClick={() => {
              handleTypeDelay("");
              handleChange("");
              setInput("");
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          />
        )}
        {filteredList && !readOnly && isDropdownOpen && filteredList.length > 0 && (
          <ul
            className="optionListWrapper"
            onBlur={() => {
              setInput("");
              setFilteredList([]);
              setIsDropdownOpen(false);
            }}
            onKeyDown={handleKeyDown} // Handle keyboard events
            tabIndex={0} // Make the list focusable
            ref={scrollableContainerRef}
          >
            <ViewportList items={filteredList} overflowAnchor="auto" withCache={true}>
              {(item, index) => optionRow(item, index)}
            </ViewportList>
          </ul>
        )}
      </div>
    </Form.Group>
  );
};

export default InputCPTCodeDropDown;
