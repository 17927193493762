const OptionListItem = ({ option, index, isSelected, searchtype, setInput, handleChange, setFilteredList,isClear}) => {

  const handleClick = () => {
    if (isClear) {
      setInput("");
    } else {
      setInput(option.code);
    }

    if (searchtype === "claims") {
      handleChange({ ...option });
    } else {
      handleChange({ ...option, value: option.code, label: option.description });
    }

    setFilteredList([]);
  };

  const getTitle = () => {
    if (searchtype === "claims") {
      return `${option.pat_name_f} ${option.pat_name_l}`;
    }

    return option.description;
  };

  return (
    <li
      key={`searchtype_${index}`}

      tabIndex={0}
      className={`listOption listOption-${index} ${isSelected ? "selected" : ""}`}
      onClick={handleClick}
      title={getTitle()}
    >
      <span className={`fw-bold optListContent ${isSelected ? "selected" : ""}`}>
        {searchtype === "claims" ? `${option.pat_name_f} ${option.pat_name_l}` : option.internalCode}
      </span>
      <span className={`fw-light optListContent ${isSelected ? "selected" : ""}`}>
        {searchtype === "claims" ? `PCN: ${option.pcn}  DOS: ${option.from_date_1}` : option.description}
      </span>
    </li>
  );
};

export default OptionListItem;
