import { CUSTOM_INS } from "constant";
import { CONFIG } from "constant";
import { INSURANCE_PROVIDER, POS_OPT, PROC_CODE_OPT } from "constant";
import { customIsEmpty } from "util/customLodash";

import { getFloatVal, isValidZipCode, fetchClientCptCodesList } from "utils";

export const formatClaimObject = (user, setting) => {
  let modifiedObj = {};
  const claimObj = user[0];

  // When Action Click From Claim Tab
  if (claimObj.employeeID) {
    const proc_array = claimObj.proc_array.map((m) => ({
      ...m,
      proc_desc: PROC_CODE_OPT.find((f) => f.value === m.proc_code)?.label,
      proc_name: PROC_CODE_OPT.find((f) => f.value === m.proc_code)?.label,
      posDesc: POS_OPT.find((f) => f.value === m.place_of_service)?.label,
      id: "prefix_" + Math.random().toString(36).substr(2, 9),
    }));

    return {
      claimObj,
      proc_array,
    };
  }

  // When Create Action Click from Patient tab
  for (const key in claimObj) {
    if (claimObj.hasOwnProperty(key)) {
      if (key === "state") {
        modifiedObj["pat_state"] = claimObj[key];
      }
      if (key === "id") {
        modifiedObj["employeeID"] = claimObj[key];
      }
      if (key === "insuranceCompany") {
        modifiedObj["payerid"] = claimObj[key];
        modifiedObj["payer_name"] = INSURANCE_PROVIDER.find((f) => f.value === claimObj[key])?.label || "";
      }
      if (key === "medicalNo") {
        modifiedObj["ins_number"] = claimObj[key];
      }
      if (key === "firstName") {
        modifiedObj["pat_name_f"] = claimObj[key];
        modifiedObj["ins_name_f"] = claimObj[key];
      }
      if (key === "lastName") {
        modifiedObj["pat_name_l"] = claimObj[key];
        modifiedObj["ins_name_l"] = claimObj[key];
      }
      if (key === "middleName") {
        modifiedObj["pat_name_m"] = claimObj[key];
        modifiedObj["ins_name_m"] = claimObj[key];
      }
      if (key === "dob") {
        modifiedObj["pat_dob"] = claimObj[key];
        modifiedObj["ins_dob"] = claimObj[key];
      }

      if (key === "city") {
        modifiedObj["pat_city"] = claimObj[key];
        modifiedObj["ins_city"] = claimObj[key];
      }
      if (key === "state") {
        modifiedObj["pat_state"] = claimObj[key];
        modifiedObj["ins_state"] = claimObj[key];
      }
      if (key === "zip") {
        modifiedObj["pat_zip"] = claimObj[key];
        modifiedObj["ins_zip"] = claimObj[key];
      }
      if (key === "street") {
        modifiedObj["pat_addr_1"] = claimObj[key];
        modifiedObj["ins_addr_1"] = claimObj[key];
      }
      if (key === "street2") {
        modifiedObj["pat_addr_2"] = claimObj[key];
        modifiedObj["ins_addr_2"] = claimObj[key];
      }
      if (key === "sex") {
        modifiedObj["pat_sex"] = claimObj[key];
        modifiedObj["ins_sex"] = claimObj[key];
      }
      if (key === "phoneNumber") {
        modifiedObj["pat_phone"] = claimObj[key];
      }
      if (key === "email") {
        modifiedObj["pat_email"] = claimObj[key];
      }
      if (key === "providerID" || key === "refProviderID" || key === "ordProviderID") {
        modifiedObj["provider"] = {
          prov_id: claimObj.providerID,
          prov_name: claimObj.providerName,
          prov_name_f: claimObj.providerFirstName,
          prov_name_l: claimObj.providerLastName,
          prov_name_m: claimObj.providerMidName,
          prov_npi: claimObj.providerNpi,
          prov_taxid: claimObj.providerTaxId,
          ref_id: claimObj.refProviderID,
          ref_npi: claimObj.refProviderNpi,
          ref_name: claimObj.refProviderName,
          ref_name_f: claimObj.refProviderFirstName,
          ref_name_l: claimObj.refProviderLastName,
          ref_name_m: claimObj.refProviderMiddleName,
          ord_prov_id: claimObj.ordProviderID,
          ord_prov_npi: claimObj.ordProviderNpi,
          ord_prov_name: claimObj.ordProviderName,
          ord_prov_name_f: claimObj.ordProviderFirstName,
          ord_prov_name_l: claimObj.ordProviderLastName,
          ord_prov_name_m: claimObj.ordProviderMiddleName,
        };
      }
      if (key === "schrID") {
        modifiedObj["pcn"] = claimObj[key];
      }
    }
  }

  if (claimObj) {
    modifiedObj["insuranceDetails"] = claimObj.insuranceDetails;
  }

  return {
    claimObj: modifiedObj,
    proc_array: [],
  };
};

const getProcData = (procData) =>
  procData.map((p) => ({
    charge: p.charge,
    diag_ref_1: p.diag_ref_1,
    diag_ref_2: p.diag_ref_2,
    diag_ref_3: p.diag_ref_3,
    diag_ref_4: p.diag_ref_4,
    from_date: p.from_date,
    place_of_service: p.place_of_service,
    proc_code: p.proc_code,
    units: p.units,
  }));

export const isClaimDataModified = (newClaim, oldClaim) => {
  const newProcArray = getProcData(newClaim.proc_array);
  const oldProcArray = getProcData(oldClaim.proc_array);

  const isProcArrayChanged = JSON.stringify(newProcArray) !== JSON.stringify(oldProcArray);

  const isDiagnosisChanged = Array.from({ length: 8 }, (_, i) => `diag_${i + 1}`).some(
    (diagKey) => newClaim[diagKey] !== oldClaim[diagKey]
  );

  return isProcArrayChanged || isDiagnosisChanged;
};

export const validateDiags = (data) => {
  let inValidDiagsSequence = false;
  let duplicateDiags = false;
  const seenValues = new Set();

  if (!data.diag_1) {
    return { isInValidDiags: true, inValidDiagsSequence, duplicateDiags };
  }

  for (let i = 1; i <= 8; i++) {
    const currentKey = `diag_${i}`;
    const currentValue = data[currentKey];

    // Check for valid sequence
    if (!currentValue && i < 8 && data[`diag_${i + 1}`]) {
      inValidDiagsSequence = true;
    }

    // Check for duplicates
    if (currentValue) {
      if (seenValues.has(currentValue)) {
        duplicateDiags = true;
      } else {
        seenValues.add(currentValue);
      }
    }
  }

  return { inValidDiagsSequence, duplicateDiags };
};

export const validateDiagReferences = (claim) => {
  const refToDiagMapping = {
    A: "diag_1",
    B: "diag_2",
    C: "diag_3",
    D: "diag_4",
    E: "diag_5",
    F: "diag_6",
    G: "diag_7",
    H: "diag_8",
  };
  let errors = [];
  let inValidCPTCharge = false;

  claim.proc_array.forEach((item, index) => {
    let isDiagnosisLink = false;
    if (getFloatVal(item.charge) === 0) {
      inValidCPTCharge = true;
    }

    for (let i = 1; i <= 4; i++) {
      const refValue = item[`diag_ref_${i}`];
      if (refValue) {
        isDiagnosisLink = true;
        const diagKey = refToDiagMapping[refValue];
        if (!claim[diagKey]) {
          errors.push(`ICT ${item.proc_code} has invalid diagnosis reference ${refValue}`);
        }
      }
    }
    if (!isDiagnosisLink && errors.length === 0) {
      errors.push(`ICT  has invalid diagnosis reference`);
    }
  });

  return { inValidCPTCharge, diagsReferenceError: errors.join("; ") };
};

export const checkProcCodes = async (arrayOfObjects) => {
  const forbiddenPairsList = await fetchClientCptCodesList();

  const forbiddenPairs = !customIsEmpty(forbiddenPairsList)
    ? forbiddenPairsList
    : [
        ["80048", "80053"],
        ["84479", "84439"],
        ["85007", "85025"],
        ["87389", "87536"],
        ["84480", "84481"],
        ["P9603", "P9604"],
        ["81003", "81015"],
        ["84436", "84439"],
        ["80069", "80053"],
        ["86140", "86141"],
        ["80076", "80053"],
      ];

  for (let pair of forbiddenPairs) {
    const [firstCode, secondCode] = pair;
    const firstExists = arrayOfObjects.some((obj) => obj.proc_code === firstCode);
    const secondExists = arrayOfObjects.some((obj) => obj.proc_code === secondCode);

    if (firstExists && secondExists) {
      return pair.join(",");
    }
  }

  return null;
};

export const containsDiagCode = (obj, code) => {
  const keys = Object.keys(obj);
  return keys.some((key) => {
    return key.startsWith("diag_") && obj[key] === code;
  });
};

const cptNotToPay = (claimObj, insObj) => {
  const { proc_array } = claimObj;

  const { cptNotToPayList = [], ictNotToPayList = [] } = insObj?.setting || {};

  if (ictNotToPayList.length > 0) {
    const diagnosisKeys = ["diag_1", "diag_2", "diag_3", "diag_4", "diag_5", "diag_6", "diag_7", "diag_8"];

    for (const key of diagnosisKeys) {
      const diag = claimObj[key];
      if (!diag) continue;

      const matchingICD = ictNotToPayList.find((item) => item?.code === diag);
      if (matchingICD) {
        return `${matchingICD.code} ICD can not be used for ${insObj?.Name}`;
      }
    }
  }

  if (cptNotToPayList.length > 0) {
    for (const proc of proc_array) {
      const { proc_code } = proc;
      if (!proc_code) continue;

      const matchingCPT = cptNotToPayList.find((item) => item?.code === proc_code);
      if (matchingCPT) {
        return `${matchingCPT.code} CPT can not be used for ${insObj?.Name}`;
      }
    }
  }

  return null;
};

export const isAllDiagAndProcCodeValidation = async ({ tempClaim, claimStatus = "draft", insuranceObj = null }) => {
  try {
    if (!tempClaim.pat_name_f || !tempClaim.pat_name_f.trim()) {
      return "Last Name is required";
    }
    if (!tempClaim.pat_name_l || !tempClaim.pat_name_l.trim()) {
      return "Last Name is required";
    }
    if (!tempClaim.pat_addr_1 || !tempClaim.pat_addr_1.trim()) {
      return "Address is required";
    }

    if (!tempClaim.pat_city || !tempClaim.pat_city.trim()) {
      return "city is required";
    }
    if (!tempClaim.pat_state || !tempClaim.pat_state.trim()) {
      return "state is required";
    }

    if (!tempClaim.payer_name || !tempClaim.payer_name.trim()) {
      return "insurance is required";
    }

    if (!CUSTOM_INS.includes(tempClaim.payerid) && (!tempClaim.ins_number || !tempClaim.ins_number.trim())) {
      return "Insurance Number is required";
    }

    if (!tempClaim.pat_zip) {
      return "Zip Code is required";
    }
    if (!isValidZipCode(tempClaim.isoCode || "US", tempClaim.pat_zip)) {
      return "Zip Code is Invalid";
    }

    if (tempClaim?.proc_array?.length === 0) {
      return "Please add Procedure";
    }

    if (claimStatus !== "draft") {
      if (!tempClaim.clia_number || !tempClaim.clia_number.trim()) {
        return "CLIA Number is required";
      }

      if (!tempClaim.payer_name || !tempClaim.payer_name.trim()) {
        return "Insurance is required";
      }

      if (!CUSTOM_INS.includes(tempClaim.payerid) && (!tempClaim.ins_number || !tempClaim.ins_number.trim())) {
        return "Insurance Number is required";
      }
      if (!tempClaim.provider) {
        return "Provider is Required Rendering and Referring";
      }
      if (tempClaim.provider && !tempClaim.provider?.prov_id) {
        return "Rendering Provider is required";
      }

      if (tempClaim.provider && !tempClaim.provider?.ref_id) {
        return "Referring Physician is required";
      }

      if (CONFIG.isBioLab && tempClaim.provider?.prov_id === tempClaim.provider?.ref_id) {
        return "Rendering and Referring Physician not be same";
      }
    }

    if (tempClaim.isInjury) {
      if (!tempClaim.injuryDate) {
        return "Please select the Accident date";
      }
      if (!tempClaim.stateOfInjury) {
        return "Please select the Accident state";
      }
    }

    let cptOrIct = null;

    if (insuranceObj) {
      cptOrIct = cptNotToPay(tempClaim, insuranceObj);
    }

    if (cptOrIct) return cptOrIct;

    const isInvalidCodeCombination = await checkProcCodes(tempClaim.proc_array);

    if (isInvalidCodeCombination) {
      return `Invalid CPT Code Combination ${isInvalidCodeCombination}.`;
    }

    if (claimStatus === "draft") {
      return;
    }

    const { isInValidDiags, inValidDiagsSequence, duplicateDiags } = validateDiags(tempClaim);

    if (isInValidDiags) {
      return "Diagnosis is missing";
    }

    if (inValidDiagsSequence) {
      return "Invalid Diagnosis Sequence, Should not be empty between two Diagnosis";
    }

    if (duplicateDiags) {
      return "Duplicate Diagnosis not allowed";
    }

    const { inValidCPTCharge, diagsReferenceError } = validateDiagReferences(tempClaim);

    if (diagsReferenceError) {
      return diagsReferenceError;
    }

    if (inValidCPTCharge) {
      return "ICT Charge amount should not be $0.00";
    }

    // if (["12302", "01192", "00882", "57016"].includes(tempClaim.payerid) && containsDiagCode(tempClaim, "Z0000")) {
    //   return `Z0000 ICD can not be used for Medicare or Medi-Cal`;
    // }

    return "";
  } catch (err) {
    console.log("Error:-", err.message);
    return err.message;
  }
};

export const resetProcArray = (proc_array) => {
  return proc_array.map((p) => {
    return {
      charge: p.charge,
      from_date: p.from_date,
      place_of_service: p.place_of_service,
      proc_code: p.proc_code,
      mod1: p.mod1,
      mod2: p.mod2,
      mod3: p.mod3,
      mod4: p.mod4,
      isClaimProcess: false,
      diag_ref_1: p.diag_ref_1,
      diag_ref_2: p.diag_ref_2,
      diag_ref_3: p.diag_ref_3,
      diag_ref_4: p.diag_ref_4,
      units: p.units,
      proc_name: p.proc_name || p.proc_desc,
      posDesc: p.posDesc,
    };
  });
};

export const formatCloneClaim = ({ selectedClaim }) => {
  return {
    locationID: selectedClaim.locationID,
    ins_addr_1: selectedClaim.ins_addr_1,
    ins_addr_2: selectedClaim.ins_addr_2,
    ins_city: selectedClaim.ins_city,
    ins_dob: selectedClaim.ins_dob,
    ins_name_f: selectedClaim.ins_name_f,
    ins_name_l: selectedClaim.ins_name_l,
    ins_name_m: selectedClaim.ins_name_m,
    ins_number: selectedClaim.ins_number,
    ins_sex: selectedClaim.ins_sex,
    ins_state: selectedClaim.ins_state,
    ins_zip: selectedClaim.ins_zip,
    pat_addr_1: selectedClaim.pat_addr_1,
    pat_addr_2: selectedClaim.pat_addr_2,
    pat_city: selectedClaim.pat_city,
    pat_country: selectedClaim.pat_country,
    pat_dob: selectedClaim.pat_dob,
    pat_name_f: selectedClaim.pat_name_f,
    pat_name_l: selectedClaim.pat_name_l,
    pat_name_m: selectedClaim.pat_name_m,
    pat_phone: selectedClaim.pat_phone,
    pat_sex: selectedClaim.pat_sex,
    pat_email: selectedClaim.pat_email,
    pat_state: selectedClaim.pat_state,
    pat_zip: selectedClaim.pat_zip,
    payer_name: selectedClaim.payer_name,
    payerid: selectedClaim.payerid,
    pcn: selectedClaim.pcn,
    provider: selectedClaim.provider,
    proc_array: resetProcArray(selectedClaim.proc_array),
    from_date_1: selectedClaim.from_date_1,
    employeeID: selectedClaim.employeeID,
    accept_assign: selectedClaim.accept_assign,
    bill_addr_1: selectedClaim.bill_addr_1,
    bill_addr_2: selectedClaim.bill_addr_2,
    bill_city: selectedClaim.bill_city,
    bill_id: selectedClaim.bill_id,
    bill_name: selectedClaim.bill_name,
    bill_npi: selectedClaim.bill_npi,
    bill_phone: selectedClaim.bill_phone,
    bill_state: selectedClaim.bill_state,
    bill_taxid: selectedClaim.bill_taxid,
    bill_taxid_type: selectedClaim.bill_taxid_type,
    clia_number: selectedClaim.clia_number,
    bill_zip: selectedClaim.bill_zip,
    diag_1: selectedClaim.diag_1,
    diag_2: selectedClaim.diag_2,
    diag_3: selectedClaim.diag_3,
    diag_4: selectedClaim.diag_4,
    diag_5: selectedClaim.diag_5,
    diag_6: selectedClaim.diag_6,
    diag_7: selectedClaim.diag_7,
    diag_8: selectedClaim.diag_8,
  };
};
