import React, { useEffect, useRef, useState } from "react";
import { isValidFile } from "../../utils";
import FullImageView from "components/Image/FullPageImageView";
import timesIcon from "assets/img/times-circle.svg";

const MultiImageInput = (props) => {
  const {
    selectedImages,
    setSelectedImages,
    type,
    title,
    cssClass,
    showCropDialog,
    disabled,
    setCopyImages,
    imageCss,
    handleRemoveImage,
    handleDrop,
  } = props;

  const [images, setImages] = useState(selectedImages);
  const [fullView, setFullView] = useState("");
  // const [captureImage, setCaptureImage] = useState(false);
  const fileInputRef = useRef();

  useEffect(() => {
    setImages(selectedImages);
  }, [selectedImages]);

  const inputFile = useRef();

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const onChangeImages = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const selectedFiles = event.currentTarget.files;
      const validImages = [];

      for (const file of selectedFiles) {
        const message = isValidFile(file);
        if (message.length === 0) {
          validImages.push(file);
        }
      }
      if (validImages.length > 0) {
        setCopyImages ? setCopyImages(validImages) : setSelectedImages(validImages);

        if (showCropDialog) showCropDialog(true);
        event.target.value = null;
      }
    }
  };

  const handleClick = (image) => {
    setFullView(image);
  };
  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <FullImageView image={fullView} handleClose={() => setFullView("")} />
      <div onDragOver={handleDragOver} onDrop={handleDrop}>
        {title && <label className="modalLineHeaders">{`${title}:`}</label>}
        <div className="d-flex flex-column align-items-center">
          <div
            className={`d-flex flex-column align-items-center file-upload-area1`}
            style={{ width: images.length <= 1 ? "34%" : "" }}
            onClick={openFileDialog}
          >
            {images && images.length > 0 ? (
              <div
                className={`d-flex align-items-center column-gap-15 ${images.length > 4 ? "small-file-icons" : ""}`}
                style={{ flexWrap: "wrap" }}
              >
                {images.map((image, index) => (
                  <div key={index} className="file-content-wrapper">
                    <div className="file-icon-container file-width-cust">
                      <img
                        src={image instanceof Object ? URL.createObjectURL(image) : image}
                        alt="File Icon"
                        className={`${images.length > 4 ? "small-icon" : "file-icon"}`}
                        onClick={() => handleClick(image instanceof Object ? URL.createObjectURL(image) : image)}
                      />
                      <span className="remove-icon remove-icon-custom" onClick={(e) => handleRemoveImage(index, e)}>
                        <img style={{ width: "18px" }} src={timesIcon} alt="" />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="mt-2 mb-0 text-center">Drag & Drop or Click to Select Files</p>
            )}
            <input
              ref={fileInputRef}
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={onChangeImages}
              multiple
              style={{ display: "none" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MultiImageInput;
